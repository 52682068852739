import React, { useEffect } from "react";
import HomepageLayout from "src/layouts/homepage";
import "./dos.css"; // Import CSS file here

export default function DoNotSellInfo() {
    return (
        <HomepageLayout>
                <iframe src="https://app.termly.io/notify/ebc9ce95-3507-412e-8b4a-8ec66954af3b" title="answerbuddy"></iframe>
        </HomepageLayout>
    );
}
